import { configureStore } from "@reduxjs/toolkit";

import rootReducer from "./root-reducer";
// import { syncState } from "./Slices/quoteSlice";

export const store = configureStore({
  reducer: rootReducer,
});

/**
 * To do: implement this to sync the quote version between tabs
 *
 */
// const broadcastChannel = new BroadcastChannel("redux_updates");

// store.subscribe(() => {
//   const state = store.getState();
//   broadcastChannel.postMessage(state);
// });

// broadcastChannel.onmessage = (event) => {
//   const receivedState = event.data.quote.version;
//   const currentState = store.getState().quote.version;
//   // if the received quote version is different from the current state, update the quote version
//   if (receivedState !== currentState) {
//     store.dispatch(syncState({ version: receivedState }));
//   }
// };

export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

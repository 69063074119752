import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { SPREAD_THE_COST } from "@/constants";
import { PaymentConsent } from "@/types/QuoteData";

const initialState = {
  type: SPREAD_THE_COST,
} as PaymentConsent;

const paymentConsents = createSlice({
  name: "paymentConsents",
  initialState,
  reducers: {
    setPaymentConsents: (state, action: PayloadAction<typeof initialState>) => {
      return {
        ...action.payload,
      };
    },
  },
});

export const { setPaymentConsents } = paymentConsents.actions;
export default paymentConsents.reducer;

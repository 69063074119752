import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { MedicalGateKeeperQuestions } from "@/types/MedicalTypes";

import { updateQuoteOnQuestion } from "./question/question.action";
import { setQuote } from "./quoteSlice";

export interface MedicalState extends MedicalGateKeeperQuestions {}

const defaultState: MedicalState = {
  agreedConfirmation: null,
  agreedHistory: null,
  agreedTreatments: null,
};

export const medicalSlice = createSlice({
  name: "medical",
  initialState: defaultState,
  reducers: {
    clearMedicalData: () => {
      return defaultState;
    },
    setMedicalData: (state, action: PayloadAction<typeof defaultState>) => {
      // eslint-disable-next-line no-param-reassign
      state = action.payload;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setQuote, (state, action) => {
        const quoteData = action.payload;
        if (quoteData.attributes.travellerDetails?.gateKeeperAnswers) {
          return quoteData.attributes.travellerDetails.gateKeeperAnswers;
        }
        return state;
      })
      .addCase(updateQuoteOnQuestion.fulfilled, (state, action) => {
        const quoteData = action.payload;
        if (quoteData?.attributes.travellerDetails?.gateKeeperAnswers) {
          return quoteData.attributes.travellerDetails.gateKeeperAnswers;
        }
        return state;
      });
  },
});

export const { setMedicalData } = medicalSlice.actions;
export default medicalSlice.reducer;

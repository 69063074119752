"use client";

import React from "react";

import BrandProvider from "./brand-provider";
import LoadingProvider from "./loading-provider";

function AppProvider({ children }: { children: React.ReactNode }) {
  return (
    <LoadingProvider>
      <BrandProvider>{children}</BrandProvider>
    </LoadingProvider>
  );
}

export default AppProvider;

"use client";

import { useSelector } from "react-redux";
import { ProgressBar } from "staysure-component-library";

import withBrand from "@/components/with-brand";
import { RootState } from "@/Redux/store";

function Progress(
  props: Readonly<{
    brand?: "staysure" | "avanti";
  }>
) {
  const { brand } = props;
  const progress = useSelector((state: RootState) => state.progress);
  return (
    <div>
      {progress !== -1 && <ProgressBar value={progress} brand={brand} />}
    </div>
  );
}

export default withBrand(Progress);

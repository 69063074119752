import { combineReducers } from "@reduxjs/toolkit";

import apiErrorSlice from "./Slices/errors/apiErrorSlice";
import medicalReducer from "./Slices/medical.slice";
import paymentConsentsSlice from "./Slices/paymentConsentsSlice";
import progressSlice from "./Slices/progressSlice";
import questionReducer from "./Slices/question/question.slice";
import quoteReducer from "./Slices/quoteSlice";

const rootReducer = combineReducers({
  quote: quoteReducer,
  question: questionReducer,
  medical: medicalReducer,
  progress: progressSlice,
  paymentConsents: paymentConsentsSlice,
  apiError: apiErrorSlice,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;

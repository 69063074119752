import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { QuoteData } from "@/types/QuoteData";

import {
  createQuoteOnQuestion,
  updateQuoteOnQuestion,
} from "./question/question.action";

const initialState = {
  type: "Quote",
  referenceId: "",
  version: "",
  channel: "",
  attributes: {
    domain: "",
    dynamicDocuments: null,
    lastVisitedPage: "",
    organiser: null,
    tripDetails: null,
    travellerDetails: null,
    marketingConsent: null,
    priceResult: null,
    optionalExtras: null,
    dynamicCoverLimit: null,
    paymentDetails: null,
  },
  quoteCompleted: {
    completed: false,
  },
  isCoverLevelChanged: false,
  isTripTypeChanged: false,
} as QuoteData;

const quoteSlice = createSlice({
  name: "quote",
  initialState,
  reducers: {
    clearQuote: () => {
      return initialState;
    },
    setQuote: (state, action: PayloadAction<typeof initialState>) => {
      return {
        ...action.payload,
      };
    },
    updateQuote: (state, action: PayloadAction<typeof initialState>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    updateQuoteCompleted: (
      state,
      action: PayloadAction<(typeof initialState)["quoteCompleted"]>
    ) => {
      // eslint-disable-next-line no-param-reassign
      state.quoteCompleted = action.payload;
      return state;
    },
    setCoverLevelChangedState: (state, action: PayloadAction<boolean>) => {
      // eslint-disable-next-line no-param-reassign
      state.isCoverLevelChanged = action.payload;
      return state;
    },
    setTripTypeChangedState: (state, action: PayloadAction<boolean>) => {
      // eslint-disable-next-line no-param-reassign
      state.isTripTypeChanged = action.payload;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createQuoteOnQuestion.fulfilled, (state, action) => {
        const quotoSaveResponse = action?.payload;
        // eslint-disable-next-line no-param-reassign
        state = (quotoSaveResponse as QuoteData) || null;
        return state;
      })
      .addCase(updateQuoteOnQuestion.fulfilled, (state, action) => {
        const quotoEditResponse = action?.payload;
        // eslint-disable-next-line no-param-reassign
        state = (quotoEditResponse as QuoteData) || null;
        return state;
      });
  },
});

export const {
  clearQuote,
  setQuote,
  updateQuote,
  updateQuoteCompleted,
  setCoverLevelChangedState,
  setTripTypeChangedState,
} = quoteSlice.actions;
export default quoteSlice.reducer;
